import React, { useState, useMemo, useContext, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Accordion, useAccordionToggle, Popover, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import get from 'lodash/get';

import Store from 'store';
import * as routes from 'constants/routes';
import ContentHeader from 'components/general/content_header';
import Loading from 'components/general/loading';
import { SvgPlusIconLarge } from 'components/general/icons';
import jobsResource from 'resources/jobs';
import expertsResource from 'resources/experts';
import { formatCurrency } from 'utils/formatters';
import { ExpertCard } from '../jobs/create/expert_invite_step';
import JobTiles from './job_tiles';
import customerStyles from './customer.module.css';

function CustomerDashboard({ history }) {
  const { state } = useContext(Store);
  const [jobs, setJobs] = useState(null);
  const [favorites, setFavorites] = useState(null);
  const [expertCount, setExpertCount] = useState();
  const [loading, setLoading] = useState(true);
  const [visibleJobCount, setVisibleJobCount] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const organization = useMemo(() => get(state, 'currentUser.organization', {}), [state]);

  const newJobPrivilege = useMemo(
    () => state.currentUser && jobs && state.currentUser.newJobPrivilege(jobs.length),
    [state.currentUser, jobs],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [jobResponse, favResponse, expertResponse] = await Promise.all([
          jobsResource.index(),
          expertsResource.favorites(),
          expertsResource.expertsWithEarnings(),
        ]);
        setJobs(jobResponse.data);
        setFavorites(favResponse.data);
        setExpertCount(expertResponse.data?.length);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useLayoutEffect(() => {
    // Widths based on bootstrap and the column widths
    // defined in job_tiles.jsx
    function updateVisibleJobCount() {
      if (window.innerWidth >= 992) {
        setVisibleJobCount(4);
      } else if (window.innerWidth >= 768) {
        setVisibleJobCount(3);
      } else if (window.innerWidth >= 576) {
        setVisibleJobCount(2);
      } else {
        setVisibleJobCount(1);
      }
    }

    window.addEventListener('resize', updateVisibleJobCount);
    updateVisibleJobCount();

    return () => window.removeEventListener('resize', updateVisibleJobCount);
  }, []);

  const activeJobs = jobs && jobs.filter((j) => ['created', 'active'].includes(j.status));
  const archivedJobs = jobs && jobs.filter((j) => ['closed', 'archived'].includes(j.status));
  const visibleJobs = activeJobs && activeJobs.slice(0, visibleJobCount);
  const overflowJobs = activeJobs && activeJobs.slice(visibleJobCount);
  const toggleAccordion = useAccordionToggle('overflowJobs', () => setIsAccordionOpen(!isAccordionOpen));

  const handleStartJob = () => {
    if (organization.is_embedded && expertCount < 4) {
      toast.error('Please add at least 4 team members in order to start a Job.');
      history.push(routes.INVITE_TALENTS);
      return;
    }
    history.push(routes.CREATE_JOB);
  };

  const renderOverflowJobs = () =>
    overflowJobs && (
      <Accordion>
        <Accordion.Collapse eventKey="overflowJobs">
          <JobTiles allJobs={jobs} jobs={overflowJobs} history={history} />
        </Accordion.Collapse>
        <Accordion.Toggle eventKey="overflowJobs" className={customerStyles.toggleBtn} onClick={toggleAccordion}>
          {!isAccordionOpen && (
            <>
              <span className={customerStyles.toggleBtnArrowDown}>&#9662;</span>MORE
            </>
          )}
          {isAccordionOpen && (
            <>
              <span className={customerStyles.toggleBtnArrowUp}>&#9662;</span>LESS
            </>
          )}
        </Accordion.Toggle>
      </Accordion>
    );

  const renderActiveJobs = () => (
    <div style={{ marginTop: 15, paddingBottom: 15, borderBottom: '2px solid black' }}>
      <JobTiles allJobs={jobs} jobs={visibleJobs} history={history} />
      {renderOverflowJobs()}
    </div>
  );

  const renderArchivedJobs = () => (
    <Col className={customerStyles.archivedJobs} md={5} xs={12}>
      <p className={customerStyles.header}>
        Archived Jobs
        <span className={customerStyles.badge}>{archivedJobs && archivedJobs.length}</span>
      </p>

      {archivedJobs &&
        archivedJobs.slice(0, 4).map((job) => {
          return (
            <div key={job.id}>
              <p className={customerStyles.archiveContent} onClick={() => history.push(routes.JOB(job.slug))}>
                <span>{job.title}</span>
                <span className={customerStyles.archiveDate}>
                  {moment(job.closed_at || job.updated_at).format('MM/DD/YY')}
                </span>
              </p>
            </div>
          );
        })}

      <button className={customerStyles.moreBtn} onClick={() => history.push(routes.JOB_LIST)}>
        <span className={customerStyles.toggleBtnArrowDown}>&#9662;</span>MORE
      </button>
    </Col>
  );

  const renderIdeaReport = () => (
    <Col className={customerStyles.ideaReport} md={{ span: 6, offset: 1 }} xs={12}>
      <p className={customerStyles.header}>Idea Report</p>
      <Row>
        <Col xs={4}>
          <p className={customerStyles.callout}>{jobs.length}</p>
          <p className={customerStyles.content}>
            Total
            <br />
            Jobs
          </p>
        </Col>
        <Col xs={4} style={{ textAlign: 'center' }}>
          <p className={customerStyles.callout}>{jobs.reduce((acc, job) => acc + job.idea_count, 0)}</p>
          <p className={customerStyles.content}>
            Total
            <br />
            Ideas
          </p>
        </Col>
        <Col xs={4}>
          <p className={customerStyles.callout}>{jobs.reduce((acc, job) => acc + job.comment_count, 0)}</p>
          <p className={customerStyles.content}>
            Builds &amp;
            <br />
            Riffs
          </p>
        </Col>
      </Row>
    </Col>
  );

  const renderFreelancerReport = () => (
    <Col className={customerStyles.freelancerReport} xs={12}>
      <p className={customerStyles.header}>Freelancer Report</p>
      <Row>
        <Col xs={3}>
          <p className={customerStyles.callout}>{jobs.reduce((acc, job) => acc + job.experts_invited, 0)}</p>
          <p className={customerStyles.content}>
            Freelancers
            <br />
            Recruited
          </p>
        </Col>
        <Col xs={6} style={{ textAlign: 'center' }}>
          <p className={customerStyles.callout}>
            {formatCurrency(jobs.reduce((acc, job) => acc + job.payment_in_cents, 0) / 100.0)}
          </p>
          <p className={customerStyles.content}>
            Total
            <br />
            Paid
          </p>
        </Col>
        <Col xs={3}>
          <p className={customerStyles.callout}>{jobs.reduce((acc, job) => acc + job.comment_count, 0)}</p>
          <p className={customerStyles.content}>
            Favorites
            <br />
            Pool
          </p>
        </Col>
      </Row>
    </Col>
  );

  const renderTalentPool = () => (
    <Col className={customerStyles.freelancerPool} xs={12}>
      <p className={customerStyles.header}>Talent Pool</p>
      <Row className="justify-content-md-left px-2">
        {favorites &&
          favorites.map((favorite) => {
            return (
              <div key={favorite.id}>
                <ExpertCard expert={favorite} dragType="none" allowDrag={false} />
              </div>
            );
          })}
      </Row>
      <div className={customerStyles.divider} />
      <div className={customerStyles.addTalent} onClick={() => history.push(routes.INVITE_TALENTS)}>
        <SvgPlusIconLarge style={{ height: 56, width: 56 }} />
        <div className={customerStyles.addTalentText}>
          <p className={customerStyles.addTalentTitle}>Add more talent from your agency</p>
          <p>Once added, they can be recruited for any future idea jobs.</p>
        </div>
      </div>
    </Col>
  );

  return (
    <>
      <ContentHeader text={`${state.currentUser.first_name}'s Command Center`}>
        {!newJobPrivilege?.allowed && (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            overlay={
              <Popover>
                <Popover.Content>{newJobPrivilege?.reason}</Popover.Content>
              </Popover>
            }
          >
            <button className="btn btn-primary btn-slim disabled" type="button" onClick={(e) => e.preventDefault()}>
              Start New Job
            </button>
          </OverlayTrigger>
        )}
        {newJobPrivilege?.allowed && (
          <button className="btn btn-primary btn-slim" type="button" onClick={handleStartJob}>
            Start New Job
          </button>
        )}
      </ContentHeader>

      {loading && <Loading />}
      {!loading && (
        <>
          <ContentHeader
            style={{ marginTop: 10, paddingBottom: 35 }}
            subtext={activeJobs.length.toString()}
            subtextLabel={'Active Jobs'}
          ></ContentHeader>
          {renderActiveJobs()}
          <Row className={customerStyles.reportStatistics}>
            {jobs && renderArchivedJobs()}
            {jobs && renderIdeaReport()}
            {jobs && renderFreelancerReport()}
            {renderTalentPool()}
          </Row>
        </>
      )}
    </>
  );
}

export default CustomerDashboard;
