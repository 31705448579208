import html2pdf from 'html2pdf.js';

export const printPDF = async (title) => {
  const page = document.getElementById('page');
  const opt = {
    margin: 1,
    filename: `${title}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvs: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };
  html2pdf().set(opt).from(page).save();
};

export function savePageAsPDF() {
  // Function to check if the document is fully loaded
  function checkIfPageIsLoaded(callback) {
    if (document.readyState === 'complete') {
      callback(); // If the page is fully loaded, proceed to the next step
    } else {
      // If not, wait for the page to fully load
      window.addEventListener('load', callback);
    }
  }

  // Main logic for saving page as PDF
  checkIfPageIsLoaded(function () {
    // Create a hidden iframe to hold the content for printing
    var printFrame = document.createElement('iframe');
    printFrame.style.position = 'fixed';
    printFrame.style.top = '-10000px';
    document.body.appendChild(printFrame);

    // Write the content of the current page into the iframe
    var doc = printFrame.contentWindow.document;
    doc.open();
    doc.write(
      '<!DOCTYPE html><html><head>' +
        document.head.innerHTML +
        '</head><body>' +
        document.body.innerHTML +
        '</body></html>',
    );
    doc.close();

    // Wait for the content and all internal iframes to load before printing
    printFrame.onload = function () {
      printFrame.contentWindow.focus();
      printFrame.contentWindow.print();
      document.body.removeChild(printFrame); // Clean up the iframe after printing
    };
  });
}
