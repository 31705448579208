import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import classnames from 'classnames'
import Store from 'store'

import Avatar from 'components/general/avatar'
import Button from 'components/general/button'
import Loading from 'components/general/loading'
import MiniHeader from 'components/general/mini_header'
import Subheader from 'components/general/subheader'
import ReturnToTop from 'components/general/return_to_top';
import { SvgBackArrow, SvgPencilIcon, SvgTrashIcon } from 'components/general/icons'
import * as routes from 'constants/routes'
import { printPDF } from 'utils/pdf'
import ideasResource from 'resources/ideas'
import commentsResource from 'resources/comments'
import Editor from '../../editor/index.jsx'
import RichContent from './rich_content.jsx'
import Comment from './comment.jsx'
import styles from './styles.module.css'

export default function ShowIdea ({ job, refreshJob, history, match, location }) {
  const [idea, setIdea] = useState(location.state && location.state.idea)
  const fullIdeaLoaded = idea && idea.comments
  const [loading, setLoading] = useState(!fullIdeaLoaded)
  const [editingRiff, setEditingRiff] = useState(false)
  const [editorInteracting, setEditorInteracting] = useState(false)

  const editorRef = useRef()
  const [saving, setSaving] = useState(false)

  const { state } = useContext(Store)
  const [comments, setComments] = useState([])

  const showDownloadPDF = useMemo(() => state.currentUser.role === 'customer' || job.canParticipate, [state.currentUser, job])

  useEffect(() => {
    const fetchIdea = async () => {
      try {
        const response = await ideasResource.show({ params: { id: match.params.ideaId }})
        setIdea(response.data)
        setComments(response.data.comments.sort((c1, c2) => new Date(c1.created_at) - new Date(c2.created_at)))
      } catch(err) {
        toast.warn("Uh oh! We couldn’t find that Idea")
        history.push('/')
      } finally {
        setLoading(false)
      }
    }
    if(!fullIdeaLoaded) {
      fetchIdea()
    }
  }, [])

  useEffect(() => {
    if(editorRef.current?.focus && editingRiff) {
      editorRef.current.focus()
    }
  }, [editingRiff, editorRef.current])

  const navigateToJob = () => history.push(routes.JOB(job.slug))

  const submitPost = async () => {
    try {
      setSaving(true)
      const editorHtmlOutput = editorRef.current.getHtml()
      const editorState = editorRef.current.getSerializedEditorState()
      const response = await commentsResource.create({ data: {
        comment: {
          body: editorHtmlOutput,
          editor_state: editorState
        },
        idea_id: idea.id,
      }})
      setEditingRiff(false)
      setIdea(response.data)

      let newComments = [...comments]
      newComments.push(response.data.comments[response.data.comments.length-1])
      setComments(newComments)

      refreshJob()
    } catch(error) {
      console.error(error)
      toast.warn("Uh oh! We weren't able to post your riff. Please try again shortly")
    } finally {
      setSaving(false)
    }
  }

  const deleteIdea = () => {
    if (window.confirm('Are you sure you want to delete this idea?')) {
      const promise = ideasResource.destroy({params: {id: idea.id}})
      promise.then((response) => {
        refreshJob();
        toast.success("Idea successfully deleted!")
        history.push(routes.JOB(job.slug));
      }).catch((error) => {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      })
    }
  }

  function deleteComment(id){
    if (window.confirm('Are you sure you want to delete this build?')) {
      fetch(`/api/v1/comments/${id}`,{
        method: 'DELETE'
      })
      .then(()=>{
        removeCommentfromState(id)
        toast.success("Build successfully deleted!")
      }).catch((error) => {
        console.error(error)
        toast.warn("Uh oh! Something went wrong. Please try again shortly.")
      })
    }
  }

  function removeCommentfromState(commentId){
    let newComments = comments.filter(comment=> comment.id !== commentId )
    setComments(newComments)
  }

  const handleSavePDF = async () => {
    await printPDF(idea.title);
  }

  return (
    <>
      <div className={styles.back} onClick={navigateToJob}>
        <SvgBackArrow /> <span>Back to {job.title}</span>
      </div>
      { loading && !job && <Loading /> }
      { !loading && 
      <>
        <div className={styles.header}>
          <div className={styles.headerUserInfo}>
            <Avatar record={idea.author} className={styles.headerAvatar} />
            <span className={styles.headerText}>{idea.author.fullName}</span>
          </div>
          <div className={styles.actions}>
            {showDownloadPDF && (
              <Button type="primary" onClick={handleSavePDF}>
                Save Idea to PDF
              </Button>
            )}
            <div className={styles.basicActions}>
              {idea.editor_state && job.status !== 'closed' && (
                <Link
                  style={{ float: 'right' }}
                  to={{
                    pathname: `/jobs/${job.slug}/ideas/${idea.slug}/edit`,
                    state: {
                      idea: idea,
                    },
                  }}
                >
                  <SvgPencilIcon className={styles.editButton} />
                </Link>
              )}
              {job?.can_edit && <SvgTrashIcon className={styles.deleteButton} onClick={() => deleteIdea()} />}
            </div>
          </div>
        </div>
        <Subheader text={idea.title.toUpperCase()} style={{marginTop: 15, marginBottom: 22}} subtext={idea.createdAtFormatted} />
        <RichContent content={idea.content} />
        <MiniHeader style={{marginTop: 50, paddingBottom: 15}} text={`Builds and Riffs (${idea.comments.length})`} />

        { comments.map(comment => <Comment key={comment.id} job={job} comment={comment} logged_in_user={state.currentUser.id} deleteComment={deleteComment} />)}

        {job.canParticipate && <>
          <div className={styles.riffHint}>Add a new Build or Riff</div>
          { !editingRiff && <div className={styles.riffClickTarget} onClick={() => { setEditingRiff(true); }} />}
          { editingRiff &&
            <Editor
              ref={editorRef}
              onInteracting={setEditorInteracting}
            />
          }
          <button type="button"
            className={classnames('btn btn-primary btn-slim', styles.postButton)}
            onClick={submitPost}
            disabled={saving || job.status === "closed" || editorInteracting}>
            Post
          </button>
          <div className={styles.footer} />
        </>}
      </>}
      <ReturnToTop />
    </>
  )
}
